import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TimeUtils } from '@wndr/common/core/utils/time-utils';
import { AbstractModalComponent } from '@wndr/common/shared/components/abstract-modal-component/abstract-modal-component';
import { ButtonDirective } from '@wndr/common/shared/components/buttons/button.directive';
import { ModalWrapperComponent } from '@wndr/common/shared/components/modal-wrapper/modal-wrapper.component';
import { TimePipe } from '@wndr/common/shared/pipes/time.pipe';
import { filter, map, ReplaySubject, startWith, switchMap, take, tap, timer } from 'rxjs';

/** Inactive modal ID. */
export const INACTIVE_MODAL_ID = 'inactive-modal';

/** Inactive modal data. */
export type InactiveModalData = Readonly<{

	/** How much time is left until the timeout in seconds. */
	timeLeftUntilTimeoutInSeconds: number;
}>;

/**
 * Inactive modal component.
 * Returns false, when user continues working with the app,
 * and true, when user doesn't interact with app for configured period of time.
 */
@Component({
	selector: 'wndrw-inactive-modal',
	standalone: true,
	imports: [AsyncPipe, TimePipe, ModalWrapperComponent, ButtonDirective],
	templateUrl: './inactive-modal.component.html',
	styleUrl: './inactive-modal.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InactiveModalComponent extends AbstractModalComponent<InactiveModalData, boolean> implements OnInit {
	/** @inheritdoc */
	protected override readonly id = INACTIVE_MODAL_ID;

	private readonly destroyRef = inject(DestroyRef);

	private readonly timeLeftUntilTimeoutInSeconds$ = new ReplaySubject<number>(1);

	/** To minutes and seconds. */
	protected readonly toMinutesAndSeconds = TimeUtils.toMinutesAndSeconds;

	/** Inactive timer. */
	protected readonly timer$ = this.timeLeftUntilTimeoutInSeconds$.pipe(
		switchMap(timeLeftUntilTimeoutInSeconds => timer(0, 1000).pipe(
			map(seconds => timeLeftUntilTimeoutInSeconds - (seconds + 1)),
			take(timeLeftUntilTimeoutInSeconds),
		)),
	);

	/** Whether time is completed. */
	protected readonly isTimerCompleted$ = this.timer$.pipe(
		map(seconds => seconds === 0),
		startWith(false),
	);

	public constructor() {
		super();
		effect(() => {
			this.timeLeftUntilTimeoutInSeconds$.next(this.modalData().timeLeftUntilTimeoutInSeconds);
		});
	}

	/** @inheritdoc */
	public ngOnInit(): void {
		this.isTimerCompleted$.pipe(
			filter(Boolean),
			tap(() => this.close({ data: true })),
			takeUntilDestroyed(this.destroyRef),
		)
			.subscribe();
	}

	/** On cancel button click handler. */
	protected onCancelButtonClick(): void {
		this.close({ data: false });
	}
}
