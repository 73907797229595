import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpStatusCode,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, shareReplay, switchMap, tap } from 'rxjs';

import { AppUrlsConfig } from '../services/app-urls.config';
import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';
import { isValidationErrorDto } from '../dtos/validation-error.dto';
import { UserService } from '../services/user.service';
import { RedirectService } from '../services/redirect.service';

const NOT_CONFIGURED_TWO_FACTOR_DETAILS_MESSAGE = 'Two factor authentication is not enabled, please configure it.';
const NOT_COMPLETED_TWO_FACTOR_DETAILS_MESSAGE = 'Please, complete two-factor authentication.';

/**
 * Catch error related to two factor authorization
 * and redirect user to pass two factor process.
 */
@Injectable()
export class TwoFactorInterceptor implements HttpInterceptor {
	private readonly appUrlsConfig = inject(AppUrlsConfig);

	private readonly redirectsService = inject(RedirectService);

	private readonly userService = inject(UserService);

	/** 2FA process.  */
	private twoFactorProcess: Observable<void> | null = null;

	/** @inheritdoc */
	public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (!this.shouldHandleTwoFactorProcessForUrl(req.url)) {
			return next.handle(req);
		}

		return next.handle(req).pipe(
			catchHttpErrorResponse(error => {
				if (this.shouldHttpErrorBeIgnored(error)) {
					throw error;
				}

				this.twoFactorProcess ??= this.userService.logout().pipe(
					tap(() => this.redirectsService.redirectToAuthPage()),
					shareReplay({ refCount: true, bufferSize: 1 }),
				);

				return this.twoFactorProcess.pipe(
					tap(() => {
						this.twoFactorProcess = null;
					}),
					switchMap(() => next.handle(req)),
				);
			}),
		);
	}

	private shouldHttpErrorBeIgnored(error: HttpErrorResponse): boolean {
		if (error.status !== HttpStatusCode.Forbidden || !isValidationErrorDto(error.error)) {
			return true;
		}
		return (
			error.error.errors[0].detail !== NOT_CONFIGURED_TWO_FACTOR_DETAILS_MESSAGE &&
			error.error.errors[0].detail !== NOT_COMPLETED_TWO_FACTOR_DETAILS_MESSAGE
		);
	}

	private shouldHandleTwoFactorProcessForUrl(url: string): boolean {
		return this.appUrlsConfig.isApplicationUrl(url);
	}
}
